import { useEffect, useState } from "react";
import WebApp from '@twa-dev/sdk'
import { useNavigate } from "react-router";
import { useUser } from "../hooks/user";
import { Button, Input, message, Typography } from "antd";
import useFetch from "../hooks/fetch";
import { useDispatch } from "react-redux";
import { update } from "../redux/userSlice";

export default function Profile(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useUser();
    const _fetch = useFetch();

    const [profileUpdate, setProfileUpdate] = useState({});
    const [loading, setLoading] = useState(false);

    function getUserProfileData(field){
        if (profileUpdate[field] !== undefined && profileUpdate[field] !== null){
            return profileUpdate[field];
        } else {
            return user[field];
        }
    }

    async function handleUpdateProfileClick(){
        try {
            setLoading(true);
            const res = await _fetch(`user/${user?._id}`, {
                method: "PATCH",
                body: profileUpdate
            });
            if (res.success){
                dispatch(update(res.response));
                message.success("Profile updated successfully.");
                setProfileUpdate({});
            } else {
                message.error(res.message);
            }
        } catch (err){
            console.error(err);
            message.error("Failed to update profile. Please try again later.");
        } finally {
            setLoading(false);
        }
    }
    
    useEffect(() => {
        function setBackAction(){
            WebApp.BackButton.show();
            WebApp.BackButton.onClick(() => {
                navigate("/");
            });
        }
        
        setBackAction();
    }, []);

    return (
        <div>
            <div
                className="w-100 flex flex-col items-center gap-y-1"
            >
                <div
                    className="bg-[var(--tg-theme-button-color)] rounded-full h-32 w-32 flex items-center justify-center p-3"
                >
                    <Typography.Title
                        level={1}
                        className="text-white"
                        style={{
                            fontSize: "5rem",
                            margin: 0,
                            textAlign: "center"
                        }}
                    >
                        {getUserProfileData("first_name")?.charAt(0) || ""}{getUserProfileData("last_name")?.charAt(0) || ""}
                    </Typography.Title>
                </div>  
                <Typography.Title
                    level={4}
                    className="text-center"
                    style={{
                        margin: 0
                    }}
                >
                    {getUserProfileData("first_name")} {getUserProfileData("last_name")}
                </Typography.Title>
            </div>
            <div
                className="flex flex-col gap-y-2 mt-2"
            >
                <label
                    htmlFor="first_name"
                    style={{
                        color: "var(--tg-theme-text-color)"
                    }}
                    className="text-left"
                >
                    First Name
                </label>
                <Input
                    id="first_name"
                    placeholder="First Name"
                    value={getUserProfileData("first_name")}
                    onChange={(e) => setProfileUpdate({
                        ...profileUpdate,
                        first_name: e.target.value
                    })}
                    style={{
                        backgroundColor: "var(--tg-theme-input-background-color)",
                        color: "var(--tg-theme-input-text-color)"
                    }}
                    className="mb-2"
                    status={profileUpdate.first_name === "" ? "error" : "success"}
                />
                <label
                    htmlFor="last_name"
                    style={{
                        color: "var(--tg-theme-text-color)"
                    }}
                    className="text-left"
                >
                    Last Name
                </label>
                <Input
                    id="last_name"
                    placeholder="Last Name"
                    value={getUserProfileData("last_name")}
                    onChange={(e) => setProfileUpdate({
                        ...profileUpdate,
                        last_name: e.target.value
                    })}
                    style={{
                        backgroundColor: "var(--tg-theme-input-background-color)",
                        color: "var(--tg-theme-input-text-color)"
                    }}
                    className="mb-2"
                    status={profileUpdate.last_name === "" ? "error" : "success"}
                />
                <label
                    htmlFor="trust_wallet_address"
                    style={{
                        color: "var(--tg-theme-text-color)"
                    }}
                    className="text-left"
                >
                    Trust Wallet Address
                </label>
                <Input
                    id="trust_wallet_address"
                    placeholder="Trust Wallet Address"
                    value={getUserProfileData("trust_wallet_address")}
                    onChange={(e) => setProfileUpdate({
                        ...profileUpdate,
                        trust_wallet_address: e.target.value
                    })}
                    style={{
                        backgroundColor: "var(--tg-theme-input-background-color)",
                        color: "var(--tg-theme-input-text-color)"
                    }}
                    className="mb-2"
                    status={profileUpdate.trust_wallet_address === "" ? "error" : "success"}
                />
            </div>
            <Button
                onClick={handleUpdateProfileClick}
                loading={loading}
                disabled={loading || Object.keys(profileUpdate).length === 0}
                style={{
                    backgroundColor: "var(--tg-theme-button-color)",
                    color: "var(--tg-theme-button-text-color)"
                }}
                className="w-full mt-4 border-none"
            >
                Update Profile
            </Button>
        </div>
    )
}