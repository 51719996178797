import { Typography } from "antd";
import { GiKangaroo } from "react-icons/gi";
import { BsCoin } from "react-icons/bs";
import { useUser } from "../../hooks/user";
import { useConsts } from "../../hooks/consts";
import { useNotifications } from "../../hooks/notification";
import { HiMiniBell, HiMiniBellAlert } from "react-icons/hi2";
import {
    formatNumber
} from "../../services/formatNumber";
import { Link } from "react-router-dom";

export default function Header({
    showStats = true
}){
    const user = useUser();
    const consts = useConsts();
    const notifications = useNotifications();
    const notificationsLength = notifications?.length || 0;
    const stats = [
        {
            id: "user-stats-1",
            title: "Earn per Tap",
            value: <span
                className="inline-flex items-center gap-x-1"
            >
                <BsCoin
                    color="#66FF00"
                />
                <span>
                    {`+ ${user.earn_per_hop}`}
                </span>
            </span>,
            color: "#66FF00"
        },
        {
            id: "user-stats-2",
            title: "Coins to Level Up",
            value: <span>
                {formatNumber(consts.levelThresholds[user.level] || 0)}
            </span>,
            color: "#bddcf3"
        }
    ];
    return (
        <header>
            <div
                className="flex flex-row justify-between items-center container"
            >
                <Link
                    to="/profile"
                    className="flex items-center gap-x-2"
                >
                    <div
                        className="bg-[var(--tg-theme-button-color)] rounded-full h-10 w-10 flex items-center justify-center"    
                    >
                        <GiKangaroo
                            size={26}
                            color="var(--tg-theme-button-text-color)"
                        />
                    </div>
                    <Typography.Title
                        level={5}
                        style={{
                            margin: 0,
                            fontWeight: 500,
                            color: "var(--tg-theme-text-color)"
                        }}
                    >
                        {user.first_name} {user.last_name}
                    </Typography.Title>
                </Link>
                <Link
                    className="bg-[var(--tg-theme-button-color)] rounded-full h-10 w-10 flex items-center justify-center"
                    to="/notifications" 
                >
                    {
                        notificationsLength > 0 ? (
                            <HiMiniBellAlert
                                size={22}
                                color="var(--tg-theme-button-text-color)"
                            />
                        ) : (
                            <HiMiniBell
                                size={22}
                                color="var(--tg-theme-button-text-color)"
                            />
                        )
                    }
                </Link>
            </div>
            {
                showStats && (
                    <div
                        className="rounded-full pt-4 mt-2 relative half-glowing-border"
                    >
                        <div
                            className="flex flex-row justify-evenly items-center gap-x-2 container"
                        >
                            {
                                stats.map(stat => (
                                    <div
                                        key={stat.id}
                                        className="flex flex-1 flex-col items-center bg-[var(--tg-theme-accent-text-color)] p-1 rounded-lg"
                                    >
                                        <Typography.Text
                                            style={{
                                                margin: 0,
                                                fontWeight: 500,
                                                color: stat.color
                                            }}
                                        >
                                            {stat.title}
                                        </Typography.Text>
                                        <Typography.Title
                                            level={5}
                                            style={{
                                                margin: 0,
                                                color: "var(--tg-theme-button-text-color)"
                                            }}
                                        >
                                            {stat.value}
                                        </Typography.Title>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </header>
    )
}