import WebApp from "@twa-dev/sdk";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import GRTShadowPng from "../../assets/logo/grt-shadow.png";
import { Button, Input, InputNumber, message, Spin, Typography } from "antd";
import { useUser } from "../../hooks/user";
import useFetch from "../../hooks/fetch";
import { useDispatch } from "react-redux";
import { update } from "../../redux/userSlice";
import DollarImage from "../../assets/icons/dollar.png";
import moment from "moment";

export default function TON(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useUser();
    const _fetch = useFetch();
    const validUserIDs = ["667556a6de710bdd791c1ee8", "667d2a35381641ff9df0de60"];
    const withdrawlAvailable = validUserIDs.includes(user?._id?.toString());

    const [amount, setAmount] = useState(0);
    const [state, setState] = useState("loading"); // ["loading", "success", "creatingWithdrawlRequest"]
    const [withdrawlRequests, setWithdrawlRequests] = useState([]);

    async function handleWithdrawClick(){
        if (amount <= 0){
            message.error("Please enter a valid amount to withdraw.");
            return;
        }
        if (amount > user.coins){
            message.error("You don't have enough GRTs to withdraw.");
            return;
        }
        try {
            setState("creatingWithdrawlRequest");
            const res = await _fetch("withdrawl", {
                method: "POST",
                body: {
                    amount
                }
            });
            if (res.success){
                message.success(res.message);
                dispatch(update(res?.response?.user));
                setWithdrawlRequests([
                    res?.response?.withdrawlRequest,
                    ...withdrawlRequests
                ]);
                setAmount(0);
            } else {
                message.error(res.message);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setState("success");
        }
    }

    async function fetchWithdrawlRequests(){
        try {
            setState("loading");
            const res = await _fetch("withdrawl");
            if (res.success){
                setWithdrawlRequests(res.response);
            } else {
                message.error(res.message);
            }
        } catch (err) {
            console.error(err);
            message.error("Failed to fetch withdrawl requests.");
        } finally {
            setState("success");
        }
    }

    useEffect(() => {
        if (!user.trust_wallet_address){
            message.error("Please add your Trust Wallet address to participate in the airdrop.");
            navigate("/profile", {
                replace: true
            });
            return;
        }
        function setBackAction(){
            WebApp.BackButton.show();
            WebApp.BackButton.onClick(() => {
                navigate("/");
            });
        }
        setBackAction();   
        fetchWithdrawlRequests();
    }, []);
    return (
        <div>
            <div
                className="flex justify-center items-center"
            >
                <img
                    src={GRTShadowPng}
                    alt="GRT"
                    className="h-72 object-contain"
                />
            </div>
            <Typography.Title
                level={2}
                className="text-center"
                style={{
                    margin: 0,
                    color: "var(--tg-theme-text-color)"
                }}
            >
                {
                    withdrawlAvailable ? "Withdraw your GRT" : "Airdrop"
                }
            </Typography.Title>
            {
                !withdrawlAvailable && (
                    <Typography.Text
                        style={{
                            color: "var(--tg-theme-text-color)"
                        }}
                    >
                        Airdrop is on the way. Please stay tuned and till then keep mining.
                    </Typography.Text>
                )
            }
            {
                withdrawlAvailable && (
                    <div
                        className="w-full mt-4"
                    >
                        <p
                            className="text-center m-0 text-[var(--tg-theme-text-color)]"
                        >
                            Your GRT balance
                        </p>
                        <div
                            className="flex items-center justify-center rounded-full bg-[var(--tg-theme-button-color)] p-1 mb-4"
                        >
                            <Typography.Title
                                level={2}
                                className="text-center"
                                style={{
                                    margin: 0,
                                    color: "var(--tg-theme-button-text-color)"
                                }}
                            >
                                {user.coins} GRTs
                            </Typography.Title>
                        </div>
                        <p
                            className="text-center m-0 text-[var(--tg-theme-text-color)]"
                        >
                            Your Trust Wallet Address
                        </p>
                        <div
                            className="flex items-center justify-center rounded-lg p-1 border-2 border-[var(--tg-theme-button-color)] mb-4 border-dashed"
                        >
                            <Typography.Title
                                level={3}
                                className="text-center"
                                style={{
                                    margin: 0,
                                    color: "var(--tg-theme-text-color)"
                                }}
                            >
                                {user.trust_wallet_address}
                            </Typography.Title>
                        </div>
                        <p
                            className="text-center m-0 text-[var(--tg-theme-text-color)]"
                        >
                            Enter the amount of GRTs you want to withdraw
                        </p>
                        
                        <input
                            type="number"
                            className="mt-2 rounded-lg shadow-none p-2 bg-transparent text-2xl border-2 border-[var(--tg-theme-button-color)] border-dashed text-[var(--tg-theme-text-color)]"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            min={1}
                            max={user.coins}
                        />
                        <Button
                            type="primary"
                            loading={state === "creatingWithdrawlRequest"}
                            className="w-full mt-4 rounded-lg shadow-none border-0"
                            onClick={handleWithdrawClick}
                            disabled={amount <= 0}
                        >
                            Withdraw GRTs
                        </Button>
                        <div
                            className="mt-4"
                        >
                            <Typography.Title
                                level={4}
                                className="w-full text-left"
                                style={{
                                    margin: 0,
                                    color: "var(--tg-theme-text-color)"
                                }}
                            >
                                Withdrawl Requests
                            </Typography.Title>
                            {
                                ((state === "loading") || withdrawlRequests.length === 0 && state === "success") && (
                                    <div
                                        className="flex items-center justify-center w-full"
                                    >
                                        {
                                            state === "loading" && (
                                                <Spin
                                                    size="large"
                                                />
                                            )
                                        }
                                        {
                                            withdrawlRequests.length === 0 && state === "success" && (
                                                <Typography.Text
                                                    className="text-center"
                                                    style={{
                                                        color: "var(--tg-theme-text-color)"
                                                    }}
                                                >
                                                    No withdrawl requests
                                                </Typography.Text>
                                            )
                                        }
                                    </div>
                                )
                            }
                            <div
                                className="flex flex-col gap-y-2 w-full mt-2"
                            >
                                {
                                    withdrawlRequests.map((request) => (
                                        <div
                                            key={request._id}
                                            className="flex items-center rounded-2xl bg-[var(--tg-theme-accent-text-color)] p-1 gap-2"
                                        >
                                            <img
                                                src={DollarImage}
                                                alt="GRT"
                                                className="w-12 h-12 object-contain"
                                            />
                                            <div
                                                className="text-left flex flex-1 flex-col gap-y-1 var(--tg-theme-button-text-color)"
                                            >
                                                <h3
                                                    className="m-0 font-semibold text-xl"
                                                >
                                                    {request.amount} GRTs
                                                </h3>
                                                <p
                                                    className="m-0 text-wrap w-full break-all"
                                                >
                                                    {request.wallet_address}
                                                </p>
                                            </div>
                                            <div
                                                className="text-right flex flex-col gap-y-1 var(--tg-theme-button-text-color)"
                                            >
                                                <p
                                                    className="m-0"
                                                >
                                                    {moment(request.createdAt).format("DD MMM YYYY hh:mm A")}
                                                </p>
                                                <p
                                                    className="m-0"
                                                >
                                                    {request.status}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}